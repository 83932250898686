import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { colors } from '../../../styles/atoms/colors';
import { FormSpinner } from '../../../utils/Spinner';
import setValuesFromParams from '../../Forms/utils/setValuesFromParams';
import CloseBtn from '../components/CloseBtn';
import Text from '../components/Text';
import {
  Container,
  Details,
  FormWrapper,
  Wrapper,
} from '../styles/FormPopup.styled';

const FormPopup = ({ component, closePopup, renderInLibrary }) => {
  if (!component) return null;
  const { heading, subhead, link, ref } = component;
  const { formId, salesforceCampaignId, heading: formHeading } = ref;

  return (
    <Container renderInLibrary={renderInLibrary}>
      <Wrapper>
        <Details>
          <CloseBtn closePopup={closePopup} />
          <Text
            theme="blue"
            heading={heading}
            subhead={subhead}
            link={link}
            reference={ref}
          />
        </Details>
        <FormWrapper id="hub-form">
          <CloseBtn fillColor={colors.base.black} closePopup={closePopup} />
          <p className="form-heading">
            {formHeading || 'Request a demo today'}
          </p>
          <HubspotForm
            className="hubspot-form"
            formId={formId}
            loading={<FormSpinner />}
            onFormReady={setValuesFromParams}
            portalId={5424293}
            sfdcCampaignId={salesforceCampaignId}
          />
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};

export default FormPopup;
